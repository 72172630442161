import { render, staticRenderFns } from "./index.vue?vue&type=template&id=83ea8ef4&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=css&"
import style1 from "./index.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Animations: require('/opt/build/repo/frontend/components/animations.vue').default,ContactForm: require('/opt/build/repo/frontend/components/contact-form.vue').default})
