//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default
{
    name: "animations"
}

