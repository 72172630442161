//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Pristine from 'pristinejs';
export default{
    name: "contact-form",
    data(){
        return {
            submitted: false,
            error_onsubmit: false,
            loading: false
        }
    },
    async mounted(){

        /*
            try {
                await this.$recaptcha.init();
            } catch (e) {
                console.log(e);
            }
*/
			let defaultConfig = {
				// class of the parent element where the error/success class is added
				classTo: 'form-group',
				errorClass: 'has-danger',
				successClass: 'has-success',
				// class of the parent element where error text element is appended
				errorTextParent: 'validate',
				// type of element to create for the error text
				errorTextTag: 'div',
				// class of the error text element
				errorTextClass: 'text-help' 
			};

			let form = document.getElementById("contact-form");

			let pristine = new Pristine(form, defaultConfig, true);

            let self = this;


			form.addEventListener('submit', async function(e){
			

       			let valid = pristine.validate(); 

                let name = document.getElementById('name');
                let email = document.getElementById('email');
                let subject = document.getElementById('subject');
                let message = document.getElementById('message');

                if (valid){
                        

                    e.preventDefault();
                
                    self.loading = true;
                    /*
                    const response = await self.verifyCaptcha();

                    if (!response.success) {
                        self.$recaptcha.reset();
                        self.loading = false;
                        self.errorStatus = true;
                        self.notificationMessage =
                            "There was an error with your reCaptcha verification. Please try again.";
                        return;
                    }*/


                    await fetch('/.netlify/functions/contact', {
                        method: 'POST',
                        body: JSON.stringify({
                        name: name.value,
                        email: email.value,
                        subject: subject.value,
                        message: message.value
                    })
                    }).then(function(response) {
                        return response.json();
                    }).then(function(data) {

                        console.log(data);

                        if (data.status == 'ok'){
                            self.submitted = true;
                        } else{
                            self.error_onsubmit = true;
                        }
                        self.loading = false;
                    //    self.$recaptcha.reset();
                         


                    }).catch((error) => {
                        
                        self.loading = false;

                        self.error_onsubmit = true;
                    });
                }

			});
    },
     methods: {
        async verifyCaptcha() {
            try {
                const token = await this.$recaptcha.execute();

                const response = await this.$axios.$post(
                `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.SECRET_KEY}&response=${token}`
                );

                return response;
            } catch (error) {
                this.loading = false;
                return error;
            };                
        }
    } 
}

