//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Bg from "../components/bg.vue";

import { mapState } from "vuex";
import { mapMutations, mapGetters } from "vuex";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import ContactForm from "../components/contact-form.vue";
import Swirl from "../components/swirl.vue";
import Animations from "../components/animations.vue";

import flickity from '~/node_modules/vue-flickity/src/flickity';

export default {
  scrollToTop: true,
  layout: "default",
  transition: "fade",
  components: {
    ContactForm,
    Swirl,
    Animations,
    Bg,
    flickity
  },
  head() {
    //return this.head;
  },
  data() {
    return {
      debug: false, 
      index: null,
      pageLength: null, 
      nextLeftLimit: null,
      direction: null, 
      scrollContainer: null,
      pages: [],
      currentPageIndex: 0,
      loaded: false,
      targetResultX: null,
            flickityOptions: {
                prevNextButtons: false,
                pageDots: false,
                wrapAround: true,
                setGallerySize: true, 
                contain: true,
                cellAlign: "left",
                adaptiveHeight: true
            }
      
    };
  },
  methods: { 
    
    onInit() {
      this.$refs.flickity.on('change', (event) => { 
        this.currentPageIndex = event;
        this.$nuxt.$emit('update_page', event);


        if (this.currentPageIndex == 0) {
          this.$refs.prev_arrow.classList.add("opacity-0");
        } else {
          this.$refs.prev_arrow.classList.remove("opacity-0");
        }

      })
    }, 

    flickity_prev(){
        this.$refs.flickity.previous();        
    },
    flickity_next(){
        this.$refs.flickity.next();
    },
    ...mapMutations({
      setOffscreenState: "global/setOffscreenState",
    }),
    prevPage() {
      this.flickity_prev();
    },
    nextPage() {
      this.flickity_next();
    },
    resetZoom(){
       setInterval(() =>  	document.body.style.zoom = 1.0, 1000) 
    }, 
    
  },
  computed: {
    ...mapState({
      navigationMain(state) {
        return state.global.content.navigationMain;
      },
      offscreenState(state) {
        return state.global.offscreenState;
      },
      head(state) {
        return state.page.content.head;
      },
      content(state) {
        return state.page.content &&
          state.page.content.page &&
          state.page.content.page.content
          ? state.page.content.page.content
          : {};
      },
      product_shots(state){
        return state.page.content.product_shots
      },
      product_shot_image_intro(state){
        return state.page.content.product_shot_image_intro
      },
      product_shot_image_get_touch(state){
        return state.page.content.product_shot_image_get_touch
      },
      product_shot_image_meet(state){
        return state.page.content.product_shot_image_meet
      },
      blocks(state) {
        return state.page.content && state.page.content.blocks
          ? state.page.content.blocks
          : {};
      },
    }),
  }, 
  mounted() {

    this.resetZoom();

     
    if (!process.server) {
 

      var bgAnimate = new gsap.timeline();
      bgAnimate.to("#animate-background", {
        backgroundPosition: "-50vw 0px",
        ease: "power0.easeNone",
        duration: 30,
        yoyo: true,
        repeat: 1,
      });
      gsap.registerPlugin(MotionPathPlugin);
      gsap.to("#sun", {
        delay: 2,
        duration: 5,
        ease: "power1.inOut",
        immediateRender: true,
        motionPath: {
          path: "#path",
          align: "#path",
        },
      });

      gsap.set([".cloud_a", ".cloud_b"], { opacity: 0, autoAlpha: 1 });
      gsap.set(".cloud_c", { opacity: 0.5, autoAlpha: 1 });
      gsap.to(".cloud_a", {
        duration: 5,
        ease: "power1.inOut",
        immediateRender: true,
        opacity: 1,
        left: 40,
      });
      gsap.to(".cloud_b", {
        duration: 5,
        ease: "power1.inOut",
        immediateRender: true,
        opacity: 1,
        left: 170,
      });
      gsap.to(".cloud_c", {
        duration: 25,
        ease: "power1.inOut",
        immediateRender: true,
        opacity: 1,
        left: "65vw",
      });
      gsap.to(".cloud_d", {
        duration: 30,
        ease: "power1.inOut",
        immediateRender: true,
        opacity: 1,
        left: "60vw",
      });
      gsap.to(".cloud_e", {
        duration: 35,
        ease: "power1.inOut",
        immediateRender: true,
        opacity: 1,
        left: "70vw",
      });
      gsap.to(".cloud_f", {
        duration: 35,
        ease: "power1.inOut",
        immediateRender: true,
        opacity: 1,
        left: "80vw",
      }); 
      
    }
  },

  async fetch({ $shopify, $axios, store }) {
    let endpoint = "api/query";
    let self = this;  
    await $axios
      .$post(endpoint, {
        query: "page('home')",
        select: {
          head: "page.title.toSeoHelper",
          page: "page",
          product_shots: "page.product_shot_image.toFilesHelper",
          product_shot_image_intro: "page.product_shot_image_intro.toFilesHelper",
          product_shot_image_meet: "page.product_shot_image_meet.toFilesHelper",
          product_shot_image_get_touch: "page.product_shot_image_get_touch.toFilesHelper",
          blocks: "page.blocks.toBlocksHelper",
        },
      })
      .then((resp) => {
        store.commit("page/setPageContent", resp.result);  
      }).finally(() => { 
      })
      .catch(function(error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  },
};
